<template>
  <article class="p-4 border rounded-xl shadow shadow">
    <router-link
      class="text-3xl font-medium"
      :to="{ name: 'org', params: { cid: org.companyId, oid: org.id } }"
    >
      {{ org.name }}
    </router-link>
  </article>
</template>

<script>
export default {
  name: 'OrgCard',
  props: {
    org: Object,
  },
};
</script>
