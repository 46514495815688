<template>
  <main>
    <section class="px-2 md:px-0 py-4">
      <div class="container">
        <nav class="breadcrumb" aria-label="breadcrumbs" v-if="company">
          <ul>
            <router-link tag="li" :to="{ name: 'home' }"
              ><a>{{ $t('breadcrumb_home') }}</a></router-link
            >
            <router-link
              class="is-hidden-mobile"
              tag="li"
              :to="{ name: 'companies' }"
              ><a>{{ $t('breadcrumb_companies') }}</a></router-link
            >
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"
              ><a><company-name :company="company" /></a
            ></router-link>
            <router-link tag="li" :to="{ name: 'orgs', params: { cid } }"
              ><a>{{ $t('breadcrumb_orgs') }}</a></router-link
            >
          </ul>
        </nav>
      </div>
    </section>
    <sqr-progress v-if="loading" />
    <sqr-error :error="cLoadError" />
    <sqr-error :error="loadError" />
    <section class="px-2 md:px-0 py-4">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-10-tablet is-8-desktop">
            <org-card v-for="org in orgs" :key="org.id" :org="org" />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style lang="postcss" scoped>
.links a {
  @apply block p-2 mr-4;
}
.links a:hover {
  @apply bg-blue-100;
}
</style>

<script>
import { mapState, mapActions } from 'vuex';

import company from './company';

import SqrProgress from '@/sqrd/SqrProgress';
import SqrError from '@/sqrd/SqrError';

import CompanyName from '@/components/CompanyName';
import OrgCard from '@/components/OrgCard';

export default {
  name: 'Orgs',
  mixins: [company],
  components: { SqrProgress, SqrError, CompanyName, OrgCard },
  computed: {
    ...mapState('orgs', {
      orgs: 'records',
    }),
    ...mapState('orgs', ['loading', 'loadError']),
  },
  mounted() {
    this.sub({
      path: ['companies', this.cid, 'orgs'],
      // where: [['archived', '==', false]],
      orderBy: [['name', 'asc']],
    });
  },
  methods: {
    ...mapActions('orgs', ['sub']),
  },
};
</script>
